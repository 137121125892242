<template>
  <div class="bfc-market">
    <div class="hero">
      <h1>BFC Market</h1>
    </div>

    <div class="strip">
      <p>January 2021 <span>✹ </span> March 2021</p>
      <p class="index">001</p>
    </div>

    <div class="info">
      <div class="context">
        <h2>The context</h2>
        <p>
          In january 2021, I had to help the BFC Market company to build his own
          identity and a website with the help of
          <a
            href="https://nahtael.com/"
            target="_blank"
            rel="noopener noreferrer"
            >Nathaël Santos</a
          >. So this is a school project for my second year of school and we had
          few hours per week to work on this project.
        </p>
        <p>
          BFC Market helps other companies from burgundy to expand their
          clientele from all around the world by taking part to the BFC Label
          wich is a guarantee of quality.
        </p>
      </div>
      <div class="right-info">
        <div class="contribution">
          <h2>My contribution</h2>
          <p>
            My contributions to this project is mostly the website development.
            But I was also invested through the design process by designing the
            page layout. So we came with a solution, a landing page for their
            corporate website.
          </p>
        </div>

        <div class="result">
          <h2>The result</h2>
          <p>
            The client is truely happy with our solution and we’re still looking
            forward to launch the project.
          </p>
        </div>
      </div>
    </div>

    <div class="mockup-holder section2">
      <div class="mockup">
        <img
          src="../../assets/images/projects/bfc-market.png"
          alt="the bfc market design"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bfc-market {
  margin: 5rem 2rem;
  @media screen and (max-width: 800px) {
    margin: 0 1rem;
  }
}

h1 {
  font-size: 15vw;
  line-height: 100%;
  margin: 0;
  @media screen and (min-width: 1700px) {
    font-size: 25rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 8rem;
  }
}

a {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

h2 {
  margin: 0;
  font-family: $font-body;
  color: $blue;
  font-size: 4rem;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

.hero {
  height: 40vh;
  display: flex;
  align-items: flex-end;
}

.info {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  p {
    padding: 1rem 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.context,
.right-info {
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.strip {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  p {
    padding: 0.5rem 0;
    font-size: 5rem;
    font-variation-settings: "wght" 300;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
  .index {
    text-align: end;
  }
  span {
    color: $green;
  }
}

.mockup-holder {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mockup {
  width: 50%;
  max-width: 100rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "BFCMarket",
};
</script>
